<template>
    <div class="content">

        <div class="back button" @click="$router.push({ name: 'Register' });">
            Wróć do rejestracji
        </div>

        <div class="content-inside">

            <h2>Zgoda na marketing drogą elektroniczną</h2>

            <h4>Obowiązek informacyjny:</h4>
            <ol>
                <li>Administratorem Danych Osobowych jest: Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, wpisana do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy pod numerem KRS 0000060964, NIP 527-109-31-05, REGON 011429418, wysokość kapitału zakładowego 750 000,00 PLN (dalej: &bdquo;<strong>Sp&oacute;łka</strong>&rdquo;).</li>
                <li>Twoje dane osobowe będą przetwarzane w zakresie i w spos&oacute;b zgodny z RODO, polskimi przepisami prawa przyjętymi w celu umożliwienia stosowania RODO oraz innymi obowiązującymi przepisami prawa, w celu wysyłki materiał&oacute;w marketingowych drogą elektroniczną.</li>
                <li>Twoje dane osobowe będą przetwarzane przez Sp&oacute;łkę w celach marketingu drogą elektroniczną, w celu promocji produkt&oacute;w i usług Sp&oacute;łki. Dane osobowe przetwarzane będą wyłącznie, na podstawie art. 6 ust 1 pkt a) RODO (zgoda osoby, kt&oacute;rej dane dotyczą).</li>
                <li>Posiadasz prawo dostępu do treści swoich danych oraz prawo do ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej cofnięciem.</li>
                <li>Masz prawo do wniesienia skargi do Organu Nadzorczego &ndash; Prezesa Urzędu Ochrony Danych Osobowych, jeśli uznasz, iż przetwarzanie danych osobowych Państwa dotyczących, narusza przepisy RODO, polskich przepis&oacute;w prawa przyjętych w celu umożliwienia stosowania RODO oraz innych obowiązujących przepis&oacute;w prawa.</li>
                <li>Twoje dane nie będą przetwarzane w spos&oacute;b zautomatyzowany, w tym r&oacute;wnież w formie profilowania.</li>
                <li>Dane będą przekazywane następującym odbiorcom danych - firmie świadczącej działalność marketingową, hostingodawcy.</li>
                <li>Twoje dane osobowe przechowujemy do czasy wycofania zgody na przetwarzanie danych dla cel&oacute;w marketingowych.</li>
                <li>Twoje dane mogą być przekazywane min. uprawnionym organom wyłącznie na podstawie odpowiednich przepis&oacute;w prawa.</li>
                <li>Dane osobowe nie będą przekazywane do Państwa Trzeciego (poza terytorium Unii Europejskiej oraz Europejskiego Obszaru Gospodarczego).</li>
            </ol>
            <p>Wyrażam zgodę na przetwarzanie moich danych osobowych przez Administratora danych tj. &nbsp;Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, dla cel&oacute;w marketingu drogą elektroniczną własnych produkt&oacute;w i usług, na warunkach określonych w obowiązku informacyjnym. Zgoda może być wycofana w dowolnym momencie poprzez wysłanie NIE na adres e-mail: ochrona.danych-alcon@Alcon.com. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej wycofaniem. Wyrażenie zgody ma charakter dobrowolny.</p>


        </div>
    </div>
</template>

<script>
    export default {
        name: 'PolitykaPrywatnosci'
    }
</script>
